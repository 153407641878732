import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Indoor_Cameras/IN-6001_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR HD Camera Hardware Reset",
  "path": "/Indoor_Cameras/IN-6001_HD/Camera_Reset/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-6001 HD Indoor IP Camera Factory Reset",
  "image": "./P_SearchThumb_IN-6001HD_Reset.png",
  "social": "/images/Search/P_SearchThumb_IN-6001HD_Reset.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-6001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-6001 HD Manual Hardware Reset' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-6001 HD Indoor IP Camera Factory Reset' image='/images/Search/P_SearchThumb_IN-6001HD_Reset.png' twitter='/images/Search/P_SearchThumb_IN-6001HD_Reset.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-6001_HD/Kamera_Reset/' locationFR='/fr/Indoor_Cameras/IN-6001_HD/Camera_Reset/' crumbLabel="Reset" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "restore-your-cameras-factory-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#restore-your-cameras-factory-settings",
        "aria-label": "restore your cameras factory settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Restore your Camera´s Factory Settings`}</h2>
    <p>{`In order to reset your camera (if you have lost your password), please hold the small reset button in bottom part of the cameras casing - for outdoor cameras, the reset is located on the end of the camera cable - for min. 15 seconds. Please make sure the camera is connected to the power supply and disconnected from the LAN cable. In case the reset was incomplete the camera might become unresponsive. Please repeat the reset for min. 15 s and unplug it over night from the power supply before trying to restart it again.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      